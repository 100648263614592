.about-heading {
  font-size:calc(3vh);
  font-weight:500;
  color:#0090DA;
  text-align: left;
}

.about-anchor {
  color: white;
  width: 100%;
  height: 25vh;
}

.about-text {
  text-align: left;
}

/**CSS PORTION for skills */

.skills-anchor {
  color: white;
  width: 100%;
  height: 25vw;
}

.skills-section {
  margin-top: calc(5vh + 4vw);
}

.skills-heading{
  font-size:calc(3vh);
  font-weight:500;
  color:#0090DA;
  text-align: left;
}

@media (min-width: 768px) {

  .about-anchor {
    color: white;
    width: 100%;
    height: 4vw;
  }

  .skills-anchor {
    color: white;
    width: 100%;
    height: 4vw;
  }

  .about-heading {
    color:#0090DA;
    font-weight: 500;
    text-align: Right;
  }

  .about-text {
    text-align: left;
  }

  .skills-heading{
    font-weight:500;
    color:#0090DA;
    text-align: Right;
  }

 }


 @media (max-width: 575.98px) { 

  .skills-anchor {
    color: white;
    width: 100%;
    height: 50vw;
  }
  
 }