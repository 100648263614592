.hiddenName {
  color:white;
}

.project-heading {
  font-size: 3vh;
  font-weight:500;
  color:#0090DA;
  text-align: left;
}

.project-anchor {
  color: white;
  width: 100%;
  height: 25vh;
}

.blogranker-img {
  width: 80%;
}

.project-ind-header{
  font-weight:700;
  margin-top: 4vh;
  text-align: center;
}

.project-ind-body{
  font-weight:100;
  text-align: center;
}

.project-demo-section{
  font-weight:100;
  text-align: center;
}

.project-links{
  text-decoration: none;
  color: white;
}

.project-links:hover{
  text-decoration: none;
  color: white;
}

@media (min-width: 768px) {

  .project-container{
    margin-top: 0;
  }

  .project-heading {
    color:#0090DA;
    font-weight: 500;
    text-align: Right;
  }


  .project-ind-header{
    font-weight:300;
    margin-top: 0;
    text-align: left;
  }

  .project-ind-body{
    font-weight:100;
    text-align: left;
  }

  .project-demo-section{
    font-weight:100;
    text-align: left;
  }

  .project-anchor {
    color: white;
    width: 100%;
    height: 7vw;
  }

 }

