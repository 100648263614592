.eric-logo{
  margin-top: 15vh;
  width: calc(10vh + 50vw);
}

.scroll {
  animation: move 1s infinite alternate;
  margin-top: 5vh;
  width: calc(1vh + 2vw);
}

.scroll:hover {
  cursor: pointer;
}

@keyframes move {
  from {
      transform: translateY(0px);
  }
  to {
      transform: translateY(20px);
  }
}

.top-anchor {
  color: white;
  width: 100%;
  height: 4vw;
}

